<template>
    <div class="main-content">
        <router-view></router-view>
    </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    created() {},
    methods: {}
  };
</script>

<style scoped lang="less"></style>
